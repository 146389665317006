/** Start Global JS Vars **/
var g_site_jsconfig = {
  site_widget: {
    gp_widget_yamaps_1: {
      center: [55.85404, 37.38926], //[55.85404, 37.38926]
      placemark_1: {
        center: [55.851991, 37.389520],
        balloonHeader: 'Автотехцентр "Ралли-Сервис" Митино',
        balloonContent: '<p class="ya-maps_address">г. Москва, 1-й Митинский переулок, д. 25</p><p class="ya-maps_time-work-title">График работы:</p><span class="ya-maps_time-work-1">с 08:00 до 21:00</span><br /><span class="ya-maps_time-work-2">без выходных и перерывов на обед</span>',
        iconColor: '#c01d0e',
        preset: 'islands#icon' // https://tech.yandex.ru/maps/doc/jsapi/2.1/ref/reference/option.presetStorage-docpage/
      }
    }
  }
};
/** End Global JS Vars **/